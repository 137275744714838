@import url(/node_modules/@syncfusion/ej2-base/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-buttons/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-calendars/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-dropdowns/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-inputs/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-navigations/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-popups/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-splitbuttons/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-react-grids/styles/material.css);
.e-clickable {
  cursor: pointer; }
