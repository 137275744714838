.qr_cont_overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-size: 55px;
  text-align: center;
  background: #ffffff57;
  color: black;
  transition: all 0.3s;
  opacity:0;
  display: 'flex';
  align-items: center;
  justify-content: center;
}

.qr_cont_overlay:hover {opacity: 1}