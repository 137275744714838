#wrapper .icon-menu {
  font-size: 24px;
}


@media(max-width:500px) {
  #wrapper #maintext.main-menu-content {
      height: 800px;
  }
}

#wrapper .sidebar-menu {
  z-index: 20 !important;
  overflow-y: hidden;
}

#wrapper .dock-menu.e-menu-wrapper {
  border: 0px;
}

/* #maintext.main-menu-content {
  height: 500px;
}

#wrapper .sidebar-menu .e-menu-wrapper {
  border: 0px;
  height: 500px;
} */

#maintext .menu-content {
  padding: 15px;
}

#maintext .paragraph-content {
  padding: 15px 0;
  font-weight: normal;
  font-size: 14px;
  text-align: initial;
}

.e-bigger #maintext .paragraph-content {
  font-size: 16px;
}

#maintext .sidebar-heading {
  font-size: 16px;
  font-weight: 500;
}

.e-bigger #maintext .sidebar-heading {
  font-size: 18px;
}

/* vertical-menu styles */
.sidebar-menu .e-menu-wrapper ul .e-menu-item.e-menu-caret-icon {
  width: 220px;
}

.e-close .e-menu-wrapper ul .e-menu-item {
  width: 50px;
}

.e-close ul .e-menu-item.e-menu-caret-icon {
  padding-right: 12px;
}

.sidebar-menu.e-dock.e-close .e-menu-wrapper ul .e-menu-item .e-caret {
  display: none;
}

.sidebar-menu.e-dock.e-close .e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.sidebar-menu.e-dock.e-close .e-menu-wrapper ul.e-vertical {
  min-width: 0;
  width: 50px !important;
}

.sidebar-menu.e-dock.e-close .e-menu-wrapper ul.e-menu {
  font-size: 0;
}

.sidebar-menu.e-dock.e-close .e-menu-item .e-menu-icon {
  font-size: 20px;
  padding: 0;
}

.sidebar-menu,
.sidebar-menu .e-menu-wrapper,
.sidebar-menu .e-menu-wrapper ul {
  overflow: hidden;
  overflow-y:hidden;
}

#dockMenu {
  width: 100%;
}